<template>
  <a
    href="javascript:void(0)"
    class="buy-meth-item"
    :class="{ 'highlight': highlight, loading: isBuying }"
    @click.prevent="buyMeth"
  >
    <!-- <img
      v-if="highlight"
      class="buy-meth-item__highlight"
      src="@/assets/img/meth-bags/meth-bags-highlight.svg"
      alt="highlight"
      width="300"
      height="300"
    >-->
    <div class="buy-meth-item__image">
      <img
        :src="item.image"
        alt
      >
    </div>
    <!-- <div class="buy-meth-item__image">
      <img
        :src="item.image"
        alt
      >
    </div>
    <div class="buy-meth-item__amount">{{ item.amountText }}</div>
    <div class="buy-meth-item__btn">{{ item.priceText }}</div>-->
    <div
      v-if="isBuying"
      class="buy-meth-item__loading"
    >
      <img
        src="@/assets/img/spinner-clear.svg"
        class="spinner"
        width="50"
      >
    </div>
  </a>
</template>
<script>
import { buyMethBag } from "@/functions/buyMethBag"
import { defineComponent, ref } from "vue"
export default defineComponent({
  props: { item: Object, highlight: Boolean, },
  setup(props, { emit }) {
    const isBuying = ref(false)
    const buyMeth = async (item) => {
      if (isBuying.value) return
      isBuying.value = true
      try {
        const result = await buyMethBag(props.item.amount, props.item.price)
        if (!result) throw new Error('Couldn\'t buy Meth')
        emit('bought', item)
      } catch (err) {
        console.error(err)
      }
      isBuying.value = false
    }
    return {
      isBuying,
      buyMeth
    }
  }
})
</script>
<style lang="scss">
.buy-meth-item {
  /*background: linear-gradient(
    180deg,
    #1a101d -42.39%,
    rgba(49, 22, 56, 0.05) 145.45%
  );
  border: 1px solid #292d32;
  padding: 20px; */
  color: #fff;
  display: block;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  position: relative;
  &:hover {
    filter: brightness(0.8);
  }
  &:last-child {
    margin-right: 0;
  }
  &.highlight {
    margin-right: 80px;
  }
  &.loading {
    pointer-events: none;
  }
  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }
  &__image {
    img {
      height: auto;
      min-height: 0;
      width: 100%;
      max-width: 100%;
    }
  }
  &__amount {
    color: var(--yellow);
  }
  &__btn {
    padding: 10px 24px;
    background-color: #2664dd;
    border-radius: 24px;
    margin-top: 20px;
    transition: 0.2s;
  }
}
</style>