<template>
  <div class="buy-meth-buy">
    <div class="buy-meth-title">
      Buy More Methane Below
    </div>
    <!-- <div class="buy-meth-description">
      Buy your Methane below
    </div>-->
    <div class="buy-meth-items">
      <!-- <div class="buy-meth-item buy-meth-item-highlight">
          <img
            class="buy-meth-item__highlight"
            src="@/assets/img/meth-bags/meth-bags-highlight.svg"
            alt="highlight"
          >
          <div class="buy-meth-item__image">
            <img
              :src="favouriteItem.image"
              alt
            >
          </div>
          <div class="buy-meth-item__amount">
            {{ favouriteItem.amount }}
          </div>
          <div class="buy-meth-item__btn">
            {{ favouriteItem.price }}
          </div>
      </div>-->

      <BuyMethItem
        v-for="(item, idx) in items"
        :key="idx"
        class="buy-meth-buy__item"
        :item="item"
        @bought="$emit('bought', item)"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue"
import BuyMethItem from "./BuyMethItem.vue"

export default defineComponent({
  components: { BuyMethItem, },
  props: { items: Array, loadingItem: Number },
  setup() {

    return {
    }
  }
})
</script>
<style lang="scss">
.buy-meth-buy {
  &__item {
    margin-right: 24px;

    max-width: 190px;
  }
  @media screen and (max-width: 576px) {
    &__item {
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>