<template>
  <div class="buy-meth-congrats">
    <div class="buy-meth-title">
      Congratulations 🎉
    </div>
    <div class="buy-meth-congrats-amount">
      You have bought {{ item.amount }} Methane
    </div>
    <div class="buy-meth-congrats-prize">
      <img
        class="buy-meth-congrats-prize__highlight"
        src="@/assets/img/meth-bags/meth-bags-highlight.svg"
        alt="highlight"
      >
      <div class="buy-meth-congrats-prize__image">
        <img
          :src="item.image"
          alt
        >
      </div>
      <!-- <div class="buy-meth-congrats-prize__amount">
        {{ item.amount }} Methane
      </div> -->
      <!-- <div class="buy-meth-congrats-prize__label">
        Purchased
        <ICheckmark width="12" />
      </div> -->
    </div>
    <a
      href="#"
      class="buy-meth-congrats-continue"
      @click.prevent="$emit('close')"
    >Continue</a>
  </div>
</template>
<script>
import { defineComponent } from "vue"
import BuyMethItem from "./BuyMethItem.vue"
import ICheckmark from "@/assets/icons/checkmark.svg?inline"
export default defineComponent({
    components: { BuyMethItem, ICheckmark },
    props: { item: Object },
    setup() {


        return {}
    }
})
</script>
<style lang="scss">
.buy-meth-congrats {
    text-align: center;
    &-amount {
        color: var(--yellow);
    }
    &-prize {
        background: linear-gradient(
            180deg,
            #1a101d -42.39%,
            rgba(49, 22, 56, 0.05) 145.45%
        );
        border: 1px solid #292d32;
        padding: 20px;
        box-sizing: border-box;
        color: #fff;
        text-align: center;
        max-width: 260px;
        margin: 0 auto;
        margin-top: 15px;
        position: relative;
        &__image {
            margin: -20px;
            position: relative;
            z-index: 10;
            img {
                max-width: 100%;
            }
        }
        &__highlight {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
        }
        &__amount {
            color: var(--yellow);
        }

        &__label {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
            text-transform: uppercase;
            svg {
                border-radius: 50%;
                padding: 2px;
                background-color: var(--green);
                margin-left: 5px;
            }
        }
    }
    &-continue {
        width: 260px;
        padding: 10px 15px;
        background-color: var(--primary);
        border: none;
        color: #fff;
        border-radius: 5px;
        margin-top: 15px;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        box-sizing: border-box;
        transition: 0.2s;
        &:hover {
            filter: brightness(0.8);
        }
    }
}
</style>