<template>
  <AModalContent
    class="buy-meth-modal"
    :show-close="true"
  >
    <BuyMethCongrats
      v-if="isBought"
      :item="boughtItem"
      @close="$emit('close')"
    />
    <BuyMethBuy
      v-else
      :items="items"
      @bought="onBought"
    />
  </AModalContent>
</template>
<script>
import { computed, defineComponent, ref } from "vue"
import AModalContent from "../Modal/AModalContent.vue"
import BuyMethBuy from "../BuyMeth/BuyMethBuy.vue"
import BuyMethCongrats from "../BuyMeth/BuyMethCongrats.vue"
import { Config } from "@/config"
export default defineComponent({
  components: { AModalContent, BuyMethBuy, BuyMethCongrats },
  setup() {
    const methPrice = 0.1
    const methPrices = Config.MethBagPrices

    const items = computed(() => {
      return [
        {
          image: require('@/assets/img/buy-meth/1.jpg'),
          amountText: "1,000 Methane",
          amount: 1000,
          price: methPrices[1000],
          priceText: `${methPrices[1000]} ETH`
        },
        {
          image: require('@/assets/img/buy-meth/2.jpg'),
          amountText: "5,000 Methane",
          price: methPrices[5000],

          amount: 5000,
          priceText: `${methPrices[5000]} ETH`
        },
        {
          image: require('@/assets/img/buy-meth/3.jpg'),
          amountText: "11,000 Methane",
          amount: 11000,
          price: methPrices[11000],

          priceText: `${methPrices[11000]} ETH`
        },
      ]
    })

    const boughtItem = ref()
    const isBought = ref(false)
    const onBought = (item) => {
      boughtItem.value = item
      isBought.value = true
    }
    return { items, boughtItem, onBought, isBought }
  }
})
</script>
<style lang="scss">
.buy-meth {
  &-modal {
    background: linear-gradient(180deg, #17111f 0%, #22080a 100%);
    font-family: var(--font-oxanium);
    padding: 50px;
    box-sizing: border-box;

    width: 700px;
    max-width: 100%;
    max-height: 95%;
    overflow: auto;
    color: #fff;
  }
  &-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 5px;
    text-transform: uppercase;
    span {
      color: var(--ape-constructor-accent);
    }
  }
  &-description {
    text-align: center;
    color: #454b52;
  }
  &-items {
    margin-top: 30px;
    display: flex;
  }
  @media screen and (max-width: 576px) {
    &-title {
      font-size: 20px;
    }
    &-items {
      margin-top: 20px;
    }
    &-modal {
      padding: 25px 8px;
      padding-top: 40px;
    }
  }
}
</style>