import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import { captureError } from "@/helpers/captureError";
import { useWeb3Store } from "@/store/web3";
import { useLootBoxContract } from "@/web3/contracts";
import Web3 from "web3";

export const buyMethBag = async (amount) => {
  try {
    const wallet = useWeb3Store().walletAddress;
    const contract = useLootBoxContract(true);
    const { signature, price, timeOut } = await useApesStorageApi({
      throw: true,
      toast: { error: true },
    }).exec({
      url: "/lootBox/meth-bag",
      params: {
        amount,
        wallet,
      },
    });
    const result = await useToastTransaction(
      contract.methods.buyMethBag(amount, wallet, price.toString(), timeOut, signature),
      {
        text: `Buying ${amount} Methane`,
        sendOptions: {
          value: price.toString(),
        },
      }
    );
    
    return result;
  } catch (err) {
    captureError(err);
  }
};
